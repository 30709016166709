import React from "react";

import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const textAlign = ({ align }) => css`
    text-align: ${align};
`;
const TextAlign = styled(
    ({ align = "center", Component = "div", ...props }) => (
        <Component {...props} />
    )
)`
    ${textAlign}
`;

export default TextAlign;
