import React, { useEffect } from "react";

import { useAuth } from "react-oidc-context";

import { navigate } from "../components/shared/LinkComponent";
import LoadingIndicatorContainer from "../components/shared/LoadingIndicator";
import Margin from "../components/shared/Margin";
import TextAlign from "../components/shared/TextAlign";
import {
    getPreviousAuthPath,
    useIsAuthenticated,
} from "../providers/AuthProvider";
import pushToAnalytics from "../utils/analytics";

const LoginRedirect = () => {
    const auth = useAuth();
    const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
        if (isAuthenticated) {
            const previousPath = getPreviousAuthPath();
            pushToAnalytics("auth:login", {
                previousPath: previousPath,
            });
            void navigate(previousPath);
        }
    }, [isAuthenticated]);

    return (
        <Margin top={100} bottom={100} left={40} right={40}>
            <LoadingIndicatorContainer>
                <TextAlign align={"center"}>
                    {auth.error
                        ? "Beim Login ist ein Problem aufgetreten. Versuche es bitte erneut."
                        : "Erfolgreich angemeldet. Du wirst gleich weitergeleitet."}
                </TextAlign>
            </LoadingIndicatorContainer>
        </Margin>
    );
};

export default LoginRedirect;
